import {graphql} from 'gatsby'
import React from 'react'
import '../css/markdown.css'

interface Props {
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        title: string
      }
    }
  }
}

export default function Terms({data}: Props) {
  const {markdownRemark} = data
  const {frontmatter, html} = markdownRemark

  return (
    <div className="markdown">
      <h1>{frontmatter.title}</h1>
      <div
        className="markdown-content"
        dangerouslySetInnerHTML={{__html: html}}
      />
    </div>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
      }
    }
  }
`
